<template>
  <div>
    <search-filter
        :items="employees"
        :isActionColumnEnabled="true"
        itemView="list"
        :sortOptions="sortOptions"
        :columns="columns"
        :show-past-years-filter="true"
        @columnClick="$can('manage', 'all')?$router.push({ name: 'Employee edit', params: { employeeId: $event.id }}):false"
        @addItem="addEmployee"
    >
      <!--  =========================== -->
      <!--  ===    Results infos    === -->
      <!--  =========================== -->

      <!--  =========================== -->
      <!--  ===      Grid view      === -->
      <!--  =========================== -->
      <template v-slot:gridView="{item}">
        <card-employee
            :employee="item"
            @click="$can('manage', 'all')?$router.push({ name: 'Employee edit', params: { employeeId: item.id }}):false"
        />
      </template>


      <!--  =========================== -->
      <!--  ===      List view      === -->
      <!--  =========================== -->
      <!--      Cell employee-->
      <template #listView_cell_employee="{item}">
        <b-media vertical-align="center" @click.stop="$can('manage', 'all')?$router.push({ name: 'Employee edit', params: { employeeId: item.id }}):false">
          <template #aside>
            <b-avatar
                size="32"
                :src="item.avatar?item.avatar.url:null"
                :text="avatarText(item.firstName + ' ' + item.name)"
                variant="light-primary"
            />
          </template>
          <b-link
              class="font-weight-bold d-block text-nowrap"
          >
            {{ item._display }}
          </b-link>
        </b-media>
      </template>

      <!--      Cell email-->
      <template #listView_cell_email="{item}">
        <a :href="'mailto:'+item.email">{{ item.email }}</a>
      </template>

      <!--      Cell roles-->
      <template #listView_cell_roles="{item}">
        <icon
            v-for="(role, index) in item.roles"
            :key="index"
            v-b-tooltip.hover.top="role=='ROLE_ADMIN'?$t('Admin'):$t('User')"
            :icon="role=='ROLE_ADMIN'?'user-shield':'user'"
            :class="'text-'+(role=='ROLE_ADMIN'?'danger':'primary')"
        />
      </template>

      <!--      Cell status-->
      <template #listView_cell_status="{item}">
        <b-badge
            pill
            :variant="'light-'+(item._isCurrentlyEmployed?'success':'secondary')"
            class="text-capitalize"
        >
          {{ item._isCurrentlyEmployed?$t('UnderContract'):$t('WithoutContract') }}
        </b-badge>
      </template>

      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div v-if="$can('manage', 'all')" class="text-nowrap">

          <!--          Edit-->
          <button-edit
              @click.native.stop="$router.push({ name: 'Employee edit', params: { employeeId: item.id }})"
              v-b-tooltip.hover.left="$t('Edit')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Remove-->
          <button-delete
              @click.native.stop="deleteEmployeeAlert(item)"
              v-b-tooltip.hover.left="capitalize($t('delete'))"
              :disabled="item.id==1"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

        </div>
      </template>

    </search-filter>

    <!-- modal new employee-->
    <new-employee-modal
        :newEmployee.sync="newEmployee"
        :title="'NewEmployee'"
        :isOpen.sync="newEmployeeModalShow"
        @submitValidated="submitValidatedEmployeeLocal(newEmployee)"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mixinEmployees } from './mixinEmployees'
import { avatarText } from '@/utils/filter'

import SearchFilter from '../../../components/searchFilterV2/SearchFilter'
import CardEmployee from '@/components/card/Employee'
import NewEmployeeModal from '@/components/prompt/NewEmployee'
import store from '@/store'
import i18n from '@/libs/i18n'
import ButtonEdit from '@/components/button/Edit'
import ButtonDelete from '@/components/button/Delete'
import { capitalize } from '../../../utils/filter'

export default {
  components: {
    SearchFilter,
    CardEmployee,
    NewEmployeeModal,
    ButtonEdit,
    ButtonDelete
  },
  mixins: [mixinEmployees],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const sortOptions = ref([
      {
        text: capitalize(i18n.tc('currentlyEmployed')),
        key: '_filter_isCurrentlyEmployed',
        selected: true
      },
    ])

    const columns = ref([
      {
        display: capitalize(i18n.tc('employee')),
        key: 'employee',
        sortable: true
      },
      {
        display: capitalize(i18n.t('email')),
        key: 'email',
        sortable: true
      },
      {
        display: capitalize(i18n.t('roles')),
        key: 'roles',
        sortable: true
      },
      {
        display: capitalize(i18n.t('status')),
        key: 'status',
        sortable: true
      },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const employees = computed(() => {
      return store.getters['user/getEmployees']
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components
      avatarText,

      // Data
      sortOptions,
      columns,

      // Computed
      employees,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>